import { render, staticRenderFns } from "./ListServiceTemplate.vue?vue&type=template&id=1678eac6&scoped=true"
import script from "./ListServiceTemplate.vue?vue&type=script&lang=js"
export * from "./ListServiceTemplate.vue?vue&type=script&lang=js"
import style0 from "./ListServiceTemplate.vue?vue&type=style&index=0&id=1678eac6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1678eac6",
  null
  
)

export default component.exports